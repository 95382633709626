<template>
  <div>
    <ch-layout page search>
      <template v-slot:search>
        <ch-search
            :render-option="searchOption" :model="searchForm"
            :props="{ labelWidth: '66px'}">
        </ch-search>
      </template>
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
        </ch-table>
      </template>
      <template v-slot:page>
        <ch-page
            type="old"
            ref="page"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
  </div>
</template>

<script>
export default {
  name: "couponUseDetails",
  data(){
    return{
      searchForm:{
        receiveTime:[],
        receiveTimeEnd: "",
        receiveTimeStart: "",
        usedTime: [],
        usedTimeEnd: "",
        usedTimeStart: "",
        sharePhone: "",
        status: [], // 0=未使用, 1=已使用, 2=已过期, 3=已取消
        userNickName: "",
        voucherId: null,
        voucherName: ""
      },
      tableList: [], // table列表
      isTableLoading: false, // table加载
      statusList: ['未使用', '已使用', '已过期', '已取消']
    }
  },
  computed:{
    searchOption:function (){
      return [
        {type: 'input', label: '用户昵称', prop: 'userNickName', placeholder: "请输入用户昵称",},
        {type: 'input', label: '抵扣券名称', prop: 'voucherName', placeholder: "请输入抵扣券名称",labelWidth: "88px",},
        {type: 'input', label: '分享人手机号', prop: 'sharePhone', placeholder: "请输入分享人手机号", labelWidth: "100px",},
        {type: 'time', label: '领券时间', prop:'receiveTime',dateType: 'daterange',format:"yyyy-MM-dd HH:mm:ss", placeholder: "请选择日期",},
        {type: 'time', label: '用券时间', prop:'usedTime',dateType: 'daterange',format:"yyyy-MM-dd HH:mm:ss", placeholder: "请选择日期",},
        {type: 'select', label: '领券状态', prop:'status',props: { multiple: true,collapseTags: true }, placeholder: "请选择状态", name: "label",value: "value",
          option: [
            {label: '未使用', value: 0},
            {label: '已使用', value: 1},
            {label: '已过期', value: 2},
            {label: '已取消', value: 3},
        ]},
        {type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.searchHandle},
          ]},
      ]
    },
    tableOption:function (){
      return [
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "用户名", prop: "userNickName", showOverflowTooltip: true,},
        { column: "text", label: "抵扣券名称", prop: "name", showOverflowTooltip: true, },
        { column: "text", label: "抵扣券内容", prop: "_content", showOverflowTooltip: true,},
        { column: "text", label: "领券方式", prop: "_receiveType", showOverflowTooltip: true,},
        { column: "text", label: "分享人手机号", prop: "shareUserPhone", showOverflowTooltip: true,},
        { column: "text", label: "领券时间", prop: "createTime", showOverflowTooltip: true,},
        { column: "text", label: "用券时间", prop: "usedAt", showOverflowTooltip: true,},
        { column: "text", label: "领券状态", prop: "_status", showOverflowTooltip: true,},
      ];
    },
  },
  activated() {
    this.searchForm.voucherId = this.$route.query.id
    this.getTableList()
  },
  methods:{
    // 查询
    searchHandle() {
      if(this.searchForm.receiveTime.length > 0){
        const [start, end] = this.searchForm.receiveTime
        this.searchForm.receiveTimeStart = start
        this.searchForm.receiveTimeEnd = end
      }else {
        this.searchForm.receiveTimeStart = ""
        this.searchForm.receiveTimeEnd = ""
      }
      if(this.searchForm.usedTime.length > 0){
        const [start, end] = this.searchForm.usedTime
        this.searchForm.usedTimeStart = start
        this.searchForm.usedTimeEnd = end
      }else {
        this.searchForm.usedTimeStart = ""
        this.searchForm.usedTimeEnd = ""
      }
      this.getTableList()
    },
    // 列表数据
    getTableList() {
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/hmVoucher/detailList",this.searchForm).then((res) => {
        this.tableList = res.rows.map((item) => {
          item._status = this.statusList[item.status]
          item._content = item.threshold===0 ? "无门槛减"+ item.amount + "元" : "满"+ item.thresholdValue + "减"+ item.amount + "元"
          item._receiveType = item.receiveType === 0 ? "小程序" :item.receiveType === 1 ? "分享": "一"
          return item
        })
      }).finally(() => {this.isTableLoading = false});
    },
    addVoucher() {
      this.$router.push({name:'addVoucher'})
    }
  }
}
</script>

<style scoped>

</style>